import { useKeycloak } from "@react-keycloak/web";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
 const { keycloak, initialized } = useKeycloak();

 if (!initialized) {
  return (<div>Loading...</div>);
}

 const isLoggedIn = keycloak.authenticated;

 return isLoggedIn ? children : (<Navigate to="/" />);
};

export default PrivateRoute;