import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import {keycloak, keycloakInitOptions} from "./Keycloak"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Nav from "./components/Nav";
import WelcomePage from "./pages/Homepage";
import SecuredPage from "./pages/Securedpage";
import PrivateRoute from "./helpers/PrivateRoute";

const App = () => {
  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}  initOptions={keycloakInitOptions} >
      <BrowserRouter>
       <Nav />
       <Routes>
         <Route exact path="/" element={<WelcomePage />} />
         <Route  path="/secured" element={
          <PrivateRoute>
            <SecuredPage />
          </PrivateRoute>} />
       </Routes>
      </BrowserRouter>
     </ReactKeycloakProvider>
    </div>
  );
}

export default App;
